import md5 from "@/utils/md5";
import JSEncrypt from "jsencrypt";
import store from "@/store/index";

/**
 *  encrypt 加密字符串（包括完整性加密）
 *  @param  data
 */
export default function(data, key) {
    data = md5(data) + "-" + data;
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(key || store.state.user.publicKey);
    var encryptData = encrypt.encrypt(data); //加密后的字符串
    return encryptData;
}
