<template>
    <div id="ding_login_container"></div>
</template>

<script>
// 钉钉扫码登录
export default {
    name: "dingtalk-login",
    data() {
        return {};
    },
    methods: {
        doLogin: function () {
            let url = encodeURIComponent(location.origin + "/#/loginForward");
            console.log("redirect url: ", decodeURIComponent(url));
            let goto = encodeURIComponent(
                "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" +
                    window.dingtalkConfig.appid +
                    "&response_type=code&scope=snsapi_login&state=dingtalk&redirect_uri=" +
                    url
            );

            //生成二维码，等待扫描
            // eslint-disable-next-line no-undef
            DDLogin({
                id: "ding_login_container",
                goto: goto,
                style: "border:none;background-color:#FFFFFF;",
                width: "365",
                height: "400",
            });

            //获取扫码后的临时授权码
            var handleMessage = function (event) {
                // console.log(event)
                var origin = event.origin;
                // console.log("origin", event.origin);
                if (origin === "https://login.dingtalk.com") {
                    //判断是否来自ddLogin扫码事件。
                    var loginTmpCode = event.data;
                    //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
                    console.log("loginTmpCode", loginTmpCode);
                    window.location =
                        decodeURIComponent(goto) +
                        "&loginTmpCode=" +
                        loginTmpCode;
                }
            };
            if (typeof window.addEventListener !== "undefined") {
                window.addEventListener("message", handleMessage, false);
            } else if (typeof window.attachEvent !== "undefined") {
                window.attachEvent("onmessage", handleMessage);
            }
        },
    },
    mounted() {
        this.doLogin();
    },
};
</script>

<style>
#ding_login_container iframe {
    width: 100%;
}
</style>