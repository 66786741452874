    <template>
    <div class="login-container">
        <div class="logoBg">
            <div class="headTitle"></div>
        </div>
        <div class="loginFrom">
            <el-form ref="loginForm"
                     :model="loginForm"
                     :rules="loginRules"
                     class="login-form"
                     auto-complete="on"
                     label-position="left">
                <div class="title-container">
                    <h3 class="title">{{ $t("login.title") }}</h3>
                    <!-- <lang-select class="set-language" /> -->
                </div>

                <el-tabs v-model="activeName">
                    <el-tab-pane label="账号密码登录"
                                 name="cardNo">
                        <div class="tabContainer"
                             v-if="activeName == 'cardNo'">
                            <el-form-item prop="username">
                                <el-input class="username"
                                          v-model="loginForm.username"
                                          :placeholder="$t('login.username')"
                                          name="username"
                                          type="text"
                                          auto-complete="on" />
                            </el-form-item>

                            <el-form-item prop="password">
                                <el-input class="password"
                                          v-model="loginForm.password"
                                          :type="passwordType"
                                          :placeholder="$t('login.password')"
                                          name="password"
                                          auto-complete="on"
                                          @keyup.enter.native="handleLogin" />
                                <span class="show-pwd"
                                      @click="showPwd">
                                    <!-- <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" /> -->
                                </span>
                            </el-form-item>

                            <el-button :loading="loading"
                                       type="primary"
                                       class="loginBtn"
                                       @click.native.prevent="handleLogin">{{ $t("login.logIn") }}</el-button>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane label="微信扫码登录"
                                 name="wx">
                        <div class="tabContainer"
                             v-if="activeName == 'wx'">
                            <weixin-login></weixin-login>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="钉钉扫码登录"
                                 name="dd">
                        <div class="tabContainer"
                             v-if="activeName == 'dd'">
                            <dingtalk-login></dingtalk-login>
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </el-form>
            <p class="copeRight">版权所有 © 2020 Wideal 保留全部权利。</p>
        </div>
    </div>
</template>

<script>
// import { validUsername } from "@/utils/validate";
// 加密
import encrypt from "@/utils/encrypt";
import DingtalkLogin from "@/views/login/dingtalkLogin";
import WeixinLogin from "@/views/login/weixinLogin";

export default {
    name: "Login",
    components: { WeixinLogin, DingtalkLogin },
    data() {
        return {
            loginForm: {
                username: "",
                password: "",
            },
            loginRules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户姓名",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                    },
                ],
            },
            passwordType: "password",
            loading: false,
            redirect: undefined,
            activeName: "cardNo",
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },
    created() {
        //先清除登录信息
        this.$store.dispatch("LogOutDone")
    },
    mounted() {
        // 获取publickey
        this.getPublicKey();
    },
    destroyed() { },
    methods: {
        showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "";
            } else {
                this.passwordType = "password";
            }
        },

        // 获取publickey
        getPublicKey() {
            this.$store.dispatch("GetPublicKey").catch(() => {
                this.$message({
                    message: "获取key失败，请刷新页面再试！",
                    type: "error",
                });
            });
        },

        // 登录
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    let loginForm = {
                        uaccount: encrypt(this.loginForm.username),
                        upwd: encrypt(this.loginForm.password),
                    };
                    this.$store
                        .dispatch("LoginByUsername", loginForm)
                        .then((res) => {
                            res.code === 200
                                ? this.getUserInfo()
                                : (this.loading = true);
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                } else {
                    this.$message({
                        message: "请填写完整信息",
                        type: "error",
                    });
                    return false;
                }
            });
        },

        // 获取用户信息
        getUserInfo() {
            this.$store
                .dispatch("GetUserInfo")
                .then(() => {
                    this.loading = false;
                    // 判断微信是否绑定
                    console.log(this.redirect)
                    this.$router.push({ path: this.redirect || "/dashboard" });
                })
                .catch(() => {
                    this.loading = false;
                    location.reload();
                });
        },
    },
};
</script>
<style>
.login-container .el-tabs__nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.login-container .el-tabs__nav div:not(.el-tabs__active-bar) {
	width: 33%;
}
.login-container .el-tabs__nav div.el-tabs__active-bar {
	width: 100px !important;
}
</style>

    <style rel="stylesheet/scss" scoped>
/* @import url('~@/assets/font/font.css'); */
.login-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.login-container .logoBg {
	float: left;
	width: calc(100% - 590px);
	height: 100%;
	background: url("../../assets/login/login_bg.png") no-repeat center center;
}
.login-container .headTitle {
	width: 445px;
	height: 41px;
	/*background: url("/assets/common/itemName.png") no-repeat center center;*/
	background-size: 100% auto;
	position: absolute;
	left: 122px;
	top: 82px;
}
.login-container .login-form {
	float: left;
	width: 590px;
	position: absolute;
	left: 50%;
	top: 50%;
	padding: 0 30px;
	transform: translateX(-50%) translateY(-50%);
}
.login-container .login-form .title {
	height: 36px;
	font-size: 36px;
	font-weight: 400;
	color: rgba(36, 180, 153, 1);
	text-align: center;
	margin-bottom: 64px;
}
.login-container .login-form ::v-deep .el-input input {
	width: 490px;
	height: 90px;
	border: 0;
	border-bottom: 2px solid rgba(235, 235, 235, 1);
	font-size: 18px;
	font-weight: 400;
	padding-left: 70px;
}
.login-container .login-form ::v-deep .username input {
	background: url("../../assets/login/ico_user.png") no-repeat 20px center;
	margin-bottom: 27px;
}
.login-container .login-form ::v-deep .password input {
	background: url("../../assets/login/ico_password.png") no-repeat 20px center;
}
.login-container .login-form .el-form-item {
	margin-bottom: 0;
}
.login-container .login-form .loginBtn {
	width: 490px;
	height: 84px;
	background: rgba(36, 180, 153, 1);
	border-radius: 10px;
	font-size: 26px;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
	margin-top: 84px;
	border: 0;
}
.loginFrom {
	position: relative;
	overflow: hidden;
	height: 100%;
}
.loginFrom .el-form {
	background: #ffffff;
}
.login-container .copeRight {
	font-size: 14px;
	font-weight: 400;
	color: rgba(193, 193, 193, 1);
	opacity: 0.6;
	text-align: center;
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
}
@media only screen and (min-width: 540px) and (max-width: 1200px) {
	.headTitle {
		width: 340px;
		top: 20px !important;
		left: 20px !important;
	}
}
@media only screen and (max-width: 590px) {
	.headTitle {
		width: 200px;
		top: 20px !important;
		left: 20px !important;
	}
}

.tabContainer {
	height: 385px;
	width: 100%;
	margin-top: 30px;
}
/* .headTitle{
      background: coral;
    } */
</style>
